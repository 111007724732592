import React, {useState} from "react"
import {Nav} from "../../components";
import '../../style/home.scss';
import PhoneHand from '../../images/home/phone-hand.svg';
import MoneyHand from '../../images/home/money-hand.svg';
import GetAQuote from '../../images/home/get-a-quote.svg';
import ShipTheDevice from '../../images/home/ship-the-device.svg';
import GetPidFast from '../../images/home/get-paid-fast.svg';
import DontThinkHowToSell from '../../images/home/dont-think-how-to-sell.svg';
import MakeMoneyEasily from '../../images/home/make-money-easily.svg';
import JustFillOurForm from '../../images/home/just-fill-our-form.svg';
import SwaptecsCan from '../../images/home/swaptecs-can.svg';
import SwapYourDevice from '../../images/home/swap-your-device-into-money.svg';
import GetAFreeQuote from '../../images/home/get-a-free-quote.svg';
import ResetYorDevice from '../../images/home/reset-phone.svg';
import ShipUsYourDevice from '../../images/home/ship-us-your-device.svg';
import GetPaypalTransfer from '../../images/home/get-paid-via-paypal.svg';
import Plus from '../../images/home/plus.svg';
import {Button, Device} from "../../components/common";
import {openUrl, toggleItemInArray} from "../../utils";
import Footer from "../../components/footer";
import {APP_URL, INTERNAL_URLS} from "../../data/consts";

const HomePage = ({location}) => {
    const [openQuestions, setOpenQuestions] = useState([]);

    const devicesData = [
        {name: 'iPhone 12 Pro Max', imageUrl: 'ip12pm.png', price: 940},
        {name: 'iPhone 12 Pro', imageUrl: 'ip12p.png', price: 870},
        {name: 'Iphone 12', imageUrl: 'ip12.png', price: 800},
        {name: 'iPhone 12 mini', imageUrl: 'ip12m.png', price: 770}
    ];

    const faqData = [
        {
            question: 'What is Swaptecs all about?',
            answer: 'Lorem..'
        },
        {
            question: 'Why should I trust Swaptecs?',
            answer: 'Lorem..'
        },
        {
            question: 'How does Swaptecs work?',
            answer: 'Lorem..'
        },
        {
            question: 'Can I sell in bulk with Swaptecs?',
            answer: 'Lorem..'
        },
        {
            question: 'How is the "Swaptecs Value" offer calculated?',
            answer: 'Lorem..'
        },
    ]

    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'Welcome to Swaptecs'}
        />

        <main className={'landing-section primary-bg'}>
            <div className="landing-section-content">
                <h1>Selling your device easier than ever</h1>
                <h4>Take our small survey and get fair value for your device</h4>

                <button
                    className={'primary big white width-220'}
                    onClick={() => openUrl(APP_URL, '_self')}
                >
                    Sell your iPhone
                </button>

                <div className="images-container">
                    <img className={'phone-image'} src={PhoneHand} alt="Exchange iphone"/>
                    <img className={'dollar-image'} src={MoneyHand} alt="Hand with money"/>
                </div>
            </div>
        </main>

        <section>
            <div className="bullet-point-section-container">
                <h2 className={'main-header-style'}>
                    Just 3 simple steps
                </h2>

                <h4>
                    Swaptecs is designed to exchange your old device for money as easy as possible
                </h4>

                <ul>
                    <li>
                        <div className="image-box">
                            <img src={GetAQuote} alt="Get a quote for your device"/>
                        </div>
                        <h5>
                            Get a quote
                        </h5>
                        <p>
                            Select the phone you want to sell and get a quick
                            quote based on your device specifications
                        </p>
                    </li>
                    <li>
                        <div className="image-box">
                            <img src={ShipTheDevice} alt="Ship the device easily"/>
                        </div>
                        <h5>
                            Ship the device
                        </h5>
                        <p>
                            We can provide you with shipping labels and materials.
                            Just pack up your device and ship
                        </p>
                    </li>
                    <li>
                        <div className="image-box">
                            <img src={GetPidFast} alt="Get paid fast with Swaptecs"/>
                        </div>
                        <h5>
                            Get paid fast
                        </h5>
                        <p>
                            As soon as your device arrives, we begin processing
                            the order so you can get paid quickly
                        </p>
                    </li>
                </ul>
            </div>
        </section>

        <section className={'text-and-image-section'}>
            <div className="content pc-start">
                <div className={'text'}>
                    <h3 className={'main-header-style'}>
                        <span className={'color-cornflower'}>Don’t think</span> how to sell your old phone
                    </h3>
                    <ul>
                        <li className={'text-with-icon'}>
                            <img src={SwaptecsCan} alt="Try our simple process of buying phones"/>
                            <p>
                                Swaptecs can do things other marketplaces can't. Try our simple process of buying phones
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="image oversize-image">
                    <img src={DontThinkHowToSell} alt="Don’t think how to sell your old phone"/>
                </div>
            </div>
        </section>

        <section className={'text-and-image-section'}>
            <div className="content">
                <div className={'text'}>
                    <h3 className={'main-header-style'}>
                        <span className={'color-cornflower'}>Make money</span> easily on your old device
                    </h3>
                    <ul>
                        <li className={'text-with-icon'}>
                            <img src={SwapYourDevice} alt="Make money easily on your old device"/>
                            <p>
                                Need funds for someting or just tired of your old phone?
                                Swap your device into money
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="image">
                    <img src={MakeMoneyEasily} alt="Swap your device into money"/>
                </div>
            </div>
        </section>

        <section className={'text-and-image-section pc-offset'}>
            <div className="content">
                <div className={'text'}>
                    <h3 className={'main-header-style'}>
                        Just <span className={'color-cornflower'}>fill our form</span> out and follow the instructions
                    </h3>
                    <ul className={'small-items'}>
                        <li className={'text-with-icon'}>
                            <img src={GetAFreeQuote} alt="Get a free and quick quote"/>
                            <p>
                                Get a free and<br/>quick quote
                            </p>
                        </li>

                        <li className={'text-with-icon'}>
                            <img src={ResetYorDevice} alt="Reset your phone"/>
                            <p>
                                Reset your<br/>phone
                            </p>
                        </li>

                        <li className={'text-with-icon'}>
                            <img src={ShipUsYourDevice} alt="Ship us your device"/>
                            <p>
                                Ship us your<br/>device
                            </p>
                        </li>

                        <li className={'text-with-icon'}>
                            <img src={GetPaypalTransfer} alt="Get transfer via PayPale"/>
                            <p>
                                Get transfer<br/>via PayPal
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="image">
                    <img src={JustFillOurForm} alt="Just fill our form out and follow the instructions"/>
                </div>
            </div>
        </section>

        <section className={'phones'}>
            <h3 className="main-header-style">
                Choose an iPhone
            </h3>
            <h4>
                Pick the device you want to sell
            </h4>

            <div className="devices">
                {
                    devicesData.map((device) => <Device
                        key={device.name}
                        data={device}
                        onClick={() => openUrl(APP_URL)}
                    />)
                }
            </div>

            <Button
                className={'phones-button width-220 primary'}
                label={'View all'}
                onClick={() => openUrl(APP_URL)}
            />
        </section>

        <section className={'faq'}>
            <div className="faq-content">
                <div className='faq-controls'>
                    <h3>
                        Frequently asked<br/>questions
                    </h3>

                    <Button
                        className={'width-220 primary mobile-hidden'}
                        label={'View all'}
                        onClick={() => openUrl(INTERNAL_URLS.FAQ, '_self')}
                    />
                </div>
                <div className="questions-controls">
                    <ul className={'questions'}>
                        {faqData.map(({question, answer}) =>
                            <li
                                key={question}
                                className={`${openQuestions.indexOf(question) > -1 ? 'active' : ''}`}
                                onClick={() => setOpenQuestions(toggleItemInArray(openQuestions, question))}
                            >
                                <div className="question-header">
                                    <h5>{question}</h5>

                                    <img
                                        src={Plus}
                                        alt={`Close ${question}`}
                                    />
                                </div>
                                <div className="question-answer">
                                    {answer}
                                </div>
                            </li>
                        )}
                    </ul>

                    <Button
                        className={'width-220 primary pc-hidden'}
                        label={'View all'}
                        onClick={() => openUrl(INTERNAL_URLS.FAQ)}
                    />
                </div>
            </div>
        </section>

        <section>
            <div className="cta">
                <h2>
                    Sell your phone easier than ever
                </h2>

                <Button
                    className={'big primary white width-220'}
                    label={'Get your quote'}
                    onClick={() => openUrl(APP_URL)}
                />
            </div>
        </section>

        <Footer />
    </div>
}

export default HomePage;
